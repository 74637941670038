// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.ranks-value {
    display: flex;
    flex-wrap: wrap;
}

.rank-chart {
    width: 400px;
}

.rank-chart-wide {
    width: 500px;
}

.rank-chart-title {
    text-align: center;
    margin-bottom: -70px;
    padding: 10px;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/Components/Ranks.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,aAAa;IACb,iBAAiB;AACrB","sourcesContent":["\n\n.ranks-value {\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.rank-chart {\n    width: 400px;\n}\n\n.rank-chart-wide {\n    width: 500px;\n}\n\n.rank-chart-title {\n    text-align: center;\n    margin-bottom: -70px;\n    padding: 10px;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
