import React, { Component } from 'react';
import './PositionLadder.css'
import './Home.css'
import { Chart } from "react-google-charts";


const optionsServerChart = {
    width: 450,
    height: 120,
    redFrom: 90,
    redTo: 100,
    yellowFrom: 85,
    yellowTo: 90,
    minorTicks: 5,
  };

export const optionsDailyPnlChart = {
    curveType: "function",
    legend: { position: 'none' },
    backgroundColor: 'transparent',
    titleTextStyle: {
        color: '#FF5733', // Example: Orange color for the title text
        fontSize: 20, // You can also set the font size
      },
      // Set horizontal axis text style
      hAxis: {
        textStyle: {
          color: '#fff', // Example: Pink color for horizontal axis text
        }
      },
      // Set vertical axis text style
      vAxis: {
        textStyle: {
          color: '#64FA64', // Example: Green color for vertical axis text
        }
      },
  
      // Set line colors for the chart
      colors: ['#64FA64'],
};

class History extends Component {
    
   
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    componentDidMount() {
    }
    
    
    componentWillUnmount() {
        
    }
    
    numberWithCommas(x, decimals = 2) {
        return Number.parseFloat(x).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    getServerData() {
        if (this.props.serverStats !== undefined) {
            return [
            ["Label", "Value"],
            ["Memory", (100.0*(this.props.serverStats[0].memoryUsageMb)/(this.props.serverStats[0].memoryFreeMb + this.props.serverStats[0].memoryUsageMb))],
            ["CPU", (Math.min(this.props.serverStats[0].cpuLoad*100.0, 80))],
            ["Threads", this.props.serverStats[0].threads],
            ];
        } else {
            return [
                ["Label", "Value"],
                ["Memory", 0],
                ["CPU", 0],
                ["Threads", 0],

                ];
        }
      }

      getDailyPnlData() {
        var result = []
        result.push(["Date", "Realised $"])
        this.props.dailyReports.forEach((item) => {
            result.push([item.date, item.realisedPnl])
        })
        return result;
      }

      getDailyVolumeData() {
        var result = []
        result.push(["Date", "Volume in $"])
        this.props.dailyReports.forEach((item) => {
            result.push([item.date, item.volume])
        })
        return result;
      }

      getDailyPnlEquityData() {
        var result = []
        result.push(["Date", "% return on equity"])
        this.props.dailyReports.forEach((item) => {
            result.push([item.date, (item.realisedPnl/item.equity)*100])
        })
        return result;
      }

    render() {
        


        var currentCyclePnl = 0;

        if (this.props.positionLadderCurrent !== undefined) {
            if (this.props.positionLadderCurrent.length !== 0) {
                this.props.positionLadderCurrent.forEach((pos) => {
                    var position = pos
                    currentCyclePnl += position.amountUsd
                })
            }
        }


        var nonGroupPnl = 0

        var pnl = 0

        this.props.positionLadder.sort(
            function(a,b) { 
                if (a === undefined || b === undefined) { 
                    return 0 
                }
                return a.currency.localeCompare(b.currency)
            }
                ).forEach((pos) => {
                    var position = pos
                    pnl += position.amountUsd
                    
                    });

        var pastPnl = 0

        var lastEquity = 1
        var lastRealised = 0
        this.props.dailyReports.forEach((item) => {
            pastPnl += item.realisedPnl
            lastEquity = item.equity
            lastRealised = item.realisedPnl
        })



        var realisedPnl = pnl - currentCyclePnl


        
        

        var nopColor = "nop-green";
        if (this.props.nop.utilization >= 78) {
            nopColor = "nop-red";
        } else if (this.props.nop.utilization >= 59) {
            nopColor = "nop-orange";
        } else {
            nopColor = "nop-green";
        }



        var todaysRealised = Number.parseFloat(this.props.currentCycleLockedPnl) + Number.parseFloat(realisedPnl) - pastPnl

       
                    
                    return (

                        <div>
                       



                            <div className='home-grid'>
                                <div className='home-grid-panel'>
                                    <div className='home-grid-panel-title '> Yesterday's Realised</div>
                                    <div className={'home-grid-panel-value pnl-green' }>{this.numberWithCommas(lastRealised.toFixed(2))}</div>
                                    <div className='home-grid-panel-sub'><span>{(((lastRealised/lastEquity)*100.0).toFixed(1))}%</span> of equity</div>
                                </div>
                                
                            </div>

                            <div className='home-grid-panel-dailyPnl'>
                                    <div className='home-grid-panel-title '> Daily % Return on Equity</div>


                                        <Chart
                                            chartType="LineChart"
                                            width="100%"
                                            height="300px"
                                            data={this.getDailyPnlEquityData()}
                                            options={optionsDailyPnlChart}
                                            />

                            </div>

                            <div className='home-grid-panel-dailyPnl'>
                                    <div className='home-grid-panel-title '> Daily PNL</div>


                                        <Chart
                                            chartType="LineChart"
                                            width="100%"
                                            height="300px"
                                            data={this.getDailyPnlData()}
                                            options={optionsDailyPnlChart}
                                            />

                            </div>

                            <div className='home-grid-panel-dailyPnl'>
                                    <div className='home-grid-panel-title '> Daily Volume</div>


                                        <Chart
                                            chartType="LineChart"
                                            width="100%"
                                            height="300px"
                                            data={this.getDailyVolumeData()}
                                            options={optionsDailyPnlChart}
                                            />

                            </div>


                        </div>
                    


                        
                        
                        );
                }
            }              
export default History;