// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    font-family: Arial, sans-serif;
  }
  .password-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .password-box label {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .password-box input[type="password"] {
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    min-width: 200px;
  }

  .password-submit {
    margin-top: 10px;;
    border: 1px solid #fff;
    padding: 10px;
    min-width: 200px;
    cursor: pointer;
    background-color: #002a09;
    border-radius: 5px;
  }

  .password-submit:hover {
    background-color: #007119;
  }

  .password-loading {
    width:100px;
  height:50px;
  display:flex;
  justify-content:center;
  align-items:center;
}`, "",{"version":3,"sources":["webpack://./src/Components/Password.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,SAAS;IACT,8BAA8B;EAChC;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;EACA;IACE,mBAAmB;IACnB,eAAe;EACjB;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,sBAAsB;IACtB,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,WAAW;EACb,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":[".password-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    margin: 0;\n    font-family: Arial, sans-serif;\n  }\n  .password-box {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  .password-box label {\n    margin-bottom: 10px;\n    font-size: 14px;\n  }\n  .password-box input[type=\"password\"] {\n    padding: 10px;\n    border: 2px solid #ccc;\n    border-radius: 5px;\n    min-width: 200px;\n  }\n\n  .password-submit {\n    margin-top: 10px;;\n    border: 1px solid #fff;\n    padding: 10px;\n    min-width: 200px;\n    cursor: pointer;\n    background-color: #002a09;\n    border-radius: 5px;\n  }\n\n  .password-submit:hover {\n    background-color: #007119;\n  }\n\n  .password-loading {\n    width:100px;\n  height:50px;\n  display:flex;\n  justify-content:center;\n  align-items:center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
