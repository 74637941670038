import React, { Component } from 'react';
import './PositionLadder.css'
import './Home.css'
import './Ranks.css'
import { Chart } from "react-google-charts";

class Ranks extends Component {
    
   
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    componentDidMount() {
    }
    
    
    componentWillUnmount() {
        
    }
    
    numberWithCommas(x, decimals = 2) {
        return Number.parseFloat(x).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }



    render() {

        const historicExchangeRanks = this.props.historicExchangeRanks;
        const symbolExchangeRanks = this.props.symbolExchangeRanks;
        const exchangeAcceptance = this.props.exchangeAcceptance
        const exchangeAcceptanceHour = this.props.exchangeAcceptanceHour
        ;

        var lpVolumeRanks = []
        var symbolVolumeRanks = []

        var exchangeAcceptanceRanks = []

        if (true) {
            
            const entries = Object.entries(historicExchangeRanks);
            const sortedEntries = entries.sort((a, b) => a[0].localeCompare(b[0]));
            const sortedObj = Object.fromEntries(sortedEntries);

            for (var i = sortedEntries.length-1; i >= 0; i--) {
                var ranksKey = sortedEntries[i][0];
                var ranks = sortedEntries[i][1];
                

                ranks = ranks.sort((a, b) => (a.volume > b.volume ? -1 : 1) )
                

                var data = [
                    ["LP", "Volume"]
                  ];
                  
                for (var j = 0; j < ranks.length; j++) {
                    data.push([ranks[j].name, ranks[j].volume])
                }
                
                const options = {
                    title: undefined,
                    chartArea: { width: "50%" },
                    legend: { position: 'none' },
                    backgroundColor: 'transparent',                
                    hAxis: {
                        textStyle: {
                          color: '#fff', // Example: Pink color for horizontal axis text
                        }
                      },
                      // Set vertical axis text style
                      vAxis: {
                        textStyle: {
                          color: '#64FA64', // Example: Green color for vertical axis text
                        }
                      },
                  };

                  lpVolumeRanks.push(<div className='rank-chart'>
                                <div className='rank-chart-title'>{ranksKey}</div>
                                <Chart
                                chartType="BarChart"
                                width="100%"
                                height="400px"
                                data={data}
                                options={options}
                            /></div>)
                  
            }
        }
        if (true) {
            var data2 = [
                ["LP", "Accepted %"]
              ];
              

            for (var k = 0; k < exchangeAcceptance.length; k++) {
                data2.push([exchangeAcceptance[k].name.replace("euronext-disclosed", "en-disclosed") + " (" + exchangeAcceptance[k].attemptedNumber + ")", exchangeAcceptance[k].acceptedPercentNumber])
            }


            var data3 = [
                ["LP", "Accepted %"]
              ];
              

            for (var k = 0; k < exchangeAcceptanceHour.length; k++) {
                data3.push([exchangeAcceptanceHour[k].name.replace("euronext-disclosed", "en-disclosed") + " (" + exchangeAcceptanceHour[k].attemptedNumber + ")", exchangeAcceptanceHour[k].acceptedPercentNumber])
            }

            const options = {
                title: undefined,
                chartArea: { width: "50%" },
                legend: { position: 'none' },
                backgroundColor: 'transparent',                
                hAxis: {
                    textStyle: {
                      color: '#fff', // Example: Pink color for horizontal axis text
                    }
                  },
                  // Set vertical axis text style
                  vAxis: {
                    textStyle: {
                      color: '#64FA64', // Example: Green color for vertical axis text
                    }
                  },
              };

              

            exchangeAcceptanceRanks.push(<div className='rank-chart-wide'>
                        <div className='rank-chart-title'>Last 1h</div>
                        <Chart
                        chartType="BarChart"
                        width="100%"
                        height="500px"
                        data={data3}
                        options={options}
                    /></div>)

            exchangeAcceptanceRanks.push(<div className='rank-chart-wide'>
                    <div className='rank-chart-title'>Last 24h</div>
                    <Chart
                    chartType="BarChart"
                    width="100%"
                    height="500px"
                    data={data2}
                    options={options}
                /></div>)
        }


        if (true) {
            
            const entries = Object.entries(symbolExchangeRanks);
            const sortedEntries = entries.sort((a, b) => b[0].localeCompare(a[0]));
            const sortedObj = Object.fromEntries(sortedEntries);

            for (var i = sortedEntries.length-1; i >= 0; i--) {
                var ranksKey = sortedEntries[i][0];
                var ranks = sortedEntries[i][1];
                

                ranks = ranks.sort((a, b) => (a.volume > b.volume ? -1 : 1) )
                

                var data = [
                    ["LP", "Volume"]
                  ];
                  
                for (var j = 0; j < ranks.length; j++) {
                    data.push([ranks[j].name, ranks[j].volume])
                }
                
                const options = {
                    title: undefined,
                    chartArea: { width: "50%" },
                    legend: { position: 'none' },
                    backgroundColor: 'transparent',                
                    hAxis: {
                        textStyle: {
                          color: '#fff', // Example: Pink color for horizontal axis text
                        }
                      },
                      // Set vertical axis text style
                      vAxis: {
                        textStyle: {
                          color: '#64FA64', // Example: Green color for vertical axis text
                        }
                      },
                  };

                  symbolVolumeRanks.push(<div className='rank-chart'>
                                <div className='rank-chart-title'>{ranksKey}</div>
                                <Chart
                                chartType="BarChart"
                                width="100%"
                                height="400px"
                                data={data}
                                options={options}
                            /></div>)
                  
            }
        }
        

        
        return (<div>

                    

                    <div className='home-grid'>

                    <div className='home-grid'>
                                <div className='home-grid-panel'>
                                    <div className='home-grid-panel-title '>LP Acceptance Rates</div>

                                    <div className='ranks-value'>
                                        {exchangeAcceptanceRanks}

                                    </div>
                                </div>
                                
                            </div>

                            <div className='home-grid-panel'>
                                    <div className='home-grid-panel-title '>LP Volume Ranks</div>

                                    <div className='ranks-value'>
                                        {lpVolumeRanks}

                                    </div>
                                </div>
                                
                            </div>

                            



                            <div className='home-grid'>
                                <div className='home-grid-panel'>
                                    <div className='home-grid-panel-title '>Symbol Volume Ranks</div>

                                    <div className='ranks-value'>

                                            {symbolVolumeRanks}
                                    </div>
                                </div>
                                
                            </div>


        </div>)

        
    }
}              
export default Ranks;