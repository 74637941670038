import React, { Component } from 'react';
import './Password.css'
import ReactLoading from "react-loading";

const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

class Password extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            passwordField: "",
            submitted : false
        }
    }
    
    componentDidMount() {
    }
    
    
    componentWillUnmount() {
        
    }

    numberWithCommas(x, decimals = 2) {
        return Number.parseFloat(x).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    
    verifyPassword() {
        if (this.state.passwordField === "ivan24!"
        || this.state.passwordField === "abba"
        || this.state.passwordField === "y24!"
        || this.state.passwordField === "am24!"
        || this.state.passwordField === "ja24!"
        || this.state.passwordField === "g24!"
        || this.state.passwordField === "d24!"
        ) {
            localStorage.setItem("loggedIn", "true")
            window.location.reload();
        } else {
            alert("Incorrect Password")
            this.setState({
                submitted: false
            })
            
        }
    }

    checkPassword() {
        this.setState({
            submitted: true
        })

        setTimeout(() => {this.verifyPassword()}, 1000)
    }

    render() {
        
        var submitButton = <div className='password-submit'
        onClick={() => {
            this.checkPassword()
        }} >Submit</div>

        if (this.state.submitted) {
            submitButton = <div className='password-loading'><ReactLoading
                type={"bars"}
                color={"#03fc4e"}
                height={"50px"}
                width={"100px"}
              /></div>
        }

        return (<div className='password-container'>
            
            <div className="password-box">
                <label for="password">Password</label>
                <input type="password" id="password" name="password"
                onChange={(e) => {
                    this.setState({
                        passwordField: e.target.value
                    })
                }} 

                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        this.checkPassword()
                    }
                }}
                />
                {submitButton}
            </div>
            
            </div>)
    }
}
    
export default Password;