import React, { Component } from 'react';
import './Config.css'

class Config extends Component {
    
    
    constructor(props) {
        super(props);

        this.state = {
            config : {
                start: "2022-01-01 00:00:00",
                end: "2023-01-01 00:00:00",
                sync: true,
                flattenDaily: true,
                flattenWeeky: false,
                weeksToFlatten: 1,
                hoursBeforeFlattening: 0,
                dayStart: 1,
                dayEnd: 21,
                useLastTakeProfitAsBase: true,
                compounding: false,
                bots : [
                    {
                        symbol: "EURUSD",
                        db: "fx",
                        config: "fx34.csv",
                        divider: 100.0,
                        direction: "long",
                        fees: 0.00001,
                        alternateDirection: false,
                        balance: 1000000
                    }
                ]
            }
        }
    }
    
    componentDidMount() {

    }
    
    
    componentWillUnmount() {
        
    }
    
    numberWithCommas(x, decimals = 2) {
        return Number.parseFloat(x).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
    render() {
        
        var bots = []

        console.log(this.state)

        this.state.config.bots.forEach((bot, index) => {
            let botForm = (
                <div className='configBot'>
                    <div className='configBot-title'>{bot.symbol}</div>

                    <div class="form-group">
                        <label for="symbol">Symbol</label>
                        <input type="text" id="symbol" name="symbol" value={bot.symbol} 
                        onChange={(e) => {
                            var config = this.state.config
                            config.bots[index].symbol = e.target.value
                            this.setState({
                                config: config
                            })

                        }} 
                        />
                    </div>

                    <div class="form-group">
                        <label for="db">Database</label>
                        <select name="db" id="db" value={bot.db}
                        onChange={(e) => {
                            var config = this.state.config
                                config.bots[index].db = e.target.value
                                this.setState({
                                    config: config
                                })
                        }} >
                            <option value="fx">fx</option>
                            <option value="binance-spot">binance-spot</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="symbol">Config</label>
                        <input type="text" id="config" name="config" value={bot.config} 
                        onChange={(e) => {
                            var config = this.state.config
                            config.bots[index].config = e.target.value
                            this.setState({
                                config: config
                            })

                        }} 
                        />
                    </div>
                    <div class="form-group">
                        <label for="divider">Divider</label>
                        <input type="text" id="divider" name="divider" value={bot.divider} 
                        onChange={(e) => {
                            var config = this.state.config
                            config.bots[index].divider = e.target.value
                            this.setState({
                                config: config
                            })

                        }} 
                        />
                    </div>
                    <div class="form-group">
                        <label for="direction">Direction</label>
                        <select name="direction" id="direction" value={bot.direction}
                        onChange={(e) => {
                            var config = this.state.config
                                config.bots[index].direction = e.target.value
                                this.setState({
                                    config: config
                                })
                        }} >
                            <option value="long">long</option>
                            <option value="short">short</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label for="fees">Fees</label>
                        <input type="text" id="fees" name="fees" value={bot.fees} 
                        onChange={(e) => {
                            var config = this.state.config
                            config.bots[index].fees = e.target.value
                            this.setState({
                                config: config
                            })

                        }} 
                        />
                    </div>

                    <div class="form-group">
                        <label for="balance">Balance</label>
                        <input type="text" id="balance" name="balance" value={bot.balance} 
                        onChange={(e) => {
                            var config = this.state.config
                            config.bots[index].balance = e.target.value
                            this.setState({
                                config: config
                            })

                        }} 
                        />
                    </div>

                    <div class="form-group">
                        <label for="alternate">Alternate</label>
                        <input type="checkbox" id="alternate" name="alternate" checked={bot.alternateDirection}
                        onChange={(e) => {
                        
                            var config = this.state.config
                            config.bots[index].alternateDirection = e.target.checked
                            this.setState({
                                config: config
                            })
                        }}  />
                    </div>

                    <div className='configClose' onClick={() => {

                            var config = this.state.config
                            delete config.bots[index]
                            this.setState({
                                config: config
                            })

                    }}>X</div>

                </div>
            )

            bots.push(botForm)
        });


        return (
            <div className='config'>
                <div className='config-title'>New Config</div>
                <div>

                <div class="form-group">
                    <label for="start">Start</label>
                    <input type="text" id="start" name="start" value={this.state.config.start} 
                    onChange={(e) => {
                        var config = this.state.config
                        config.start = e.target.value
                        this.setState({
                            config: config
                        })
                    }} 
                    />
                </div>

                <div class="form-group">
                    <label for="end">End</label>
                    <input type="text" id="end" name="end" value={this.state.config.end}
                    onChange={(e) => {
                     
                        var config = this.state.config
                        config.end = e.target.value
                        this.setState({
                            config: config
                        })
                    }} 
                     />
                </div>

                <div class="form-group">
                    <label for="sync">Sync bots</label>
                    <input type="checkbox" id="sync" name="sync" checked={this.state.config.sync}
                    onChange={(e) => {
                    
                        var config = this.state.config
                        config.sync = e.target.checked
                        this.setState({
                            config: config
                        })
                    }}  />
                </div>

                <div class="form-group">
                    <label for="flattenDaily">Flatten Daily</label>
                    <input type="checkbox" id="flattenDaily" name="flattenDaily" checked={this.state.config.flattenDaily}
                    onChange={(e) => {
                      
                        var config = this.state.config
                        config.flattenDaily = e.target.checked
                        this.setState({
                            config: config
                        })
                    }}  />
                </div>

                <div class="form-group">
                    <label for="flattenWeekly">Flatten Weekly</label>
                    <input type="checkbox" id="flattenWeekly" name="flattenWeekly" checked={this.state.config.flattenWeekly}
                    onChange={(e) => {
                        var config = this.state.config
                        config.flattenWeekly = e.target.checked
                        this.setState({
                            config: config
                        })
                    }}  />
                </div>

                <div class="form-group">
                    <label for="weeksToFlatten">Flatten Weeks</label>
                    <select name="weeksToFlatten" id="weeksToFlatten" value={this.state.config.weeksToFlatten}
                    onChange={(e) => {
                        var config = this.state.config
                        config.weeksToFlatten = e.target.value
                        this.setState({
                            config: config
                        })
                    }}  >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="hoursBeforeFlattening">Hours before flatten</label>
                    <select name="hoursBeforeFlattening" id="hoursBeforeFlattening" value={this.state.config.hoursBeforeFlattening}
                    onChange={(e) => {
                        var config = this.state.config
                        config.hoursBeforeFlattening = e.target.value
                        this.setState({
                            config: config
                        })
                    }} >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="dayStart">Day Start</label>
                    <select name="dayStart" id="dayStart" value={this.state.config.dayStart}
                    onChange={(e) => {
                        var config = this.state.config
                        config.dayStart = e.target.value
                        this.setState({
                            config: config
                        })
                    }} >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                    </select>
                </div>


                <div class="form-group">
                    <label for="dayEnd">Day End</label>
                    <select name="dayEnd" id="dayEnd" value={this.state.config.dayEnd}
                    onChange={(e) => {
                 

                        var config = this.state.config
                        config.dayEnd = e.target.value
                        this.setState({
                            config: config
                        })
                    }} >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="useLastTakeProfitAsBase">Use TP as Base</label>
                    <input type="checkbox" id="useLastTakeProfitAsBase" name="useLastTakeProfitAsBase" checked={this.state.config.useLastTakeProfitAsBase} 
                    onChange={(e) => {
                 

                        var config = this.state.config
                        config.useLastTakeProfitAsBase = e.target.checked
                        this.setState({
                            config: config
                        })

                    }} />
                </div>

                <div class="form-group">
                    <label for="compounding">Conpounding</label>
                    <input type="checkbox" id="compounding" name="compounding" checked={this.state.config.compounding}
                    onChange={(e) => {
                        var config = this.state.config
                        config.compounding = e.target.checked
                        this.setState({
                            config: config
                        })
                    }}  />
                </div>

                <div className='configBots-subhead'>Bots</div>
                {bots}
                
                <div className='configButton small' onClick={()=> {
                    var config = this.state.config
                    config.bots.push({
                        symbol: "EURUSD",
                        db: "fx",
                        config: "fx34.csv",
                        divider: 100.0,
                        direction: "long",
                        fees: 0.00001,
                        balance: 1000000,
                        alternateDirection: false
                    })
                    this.setState({
                        config: config
                    })
                    
                }}>Add Bot</div>
                <div className='space'>&nbsp;</div>
                <div style={{"float" : "right"}}>
                <div className='submitButton' onClick={()=> {
                    
                    const myData = this.state.config; // I am assuming that "this.state.myData"
                    // is an object and I wrote it to file as
                    // json

                    // create file in browser
                    const fileName = "config";
                    const json = JSON.stringify(myData, null, 2);
                    const blob = new Blob([json], { type: "application/json" });
                    const href = URL.createObjectURL(blob);

                    // create "a" HTLM element with href to file
                    const link = document.createElement("a");
                    link.href = href;
                    link.download = fileName + ".sim";
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);

                    
                }}>Download</div>
                </div>

                </div>
                <div className='configClose' onClick={() => {this.props.toggle()}}>X</div>
            </div>
            
            );
        }
    }
    
    export default Config;