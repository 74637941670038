import React, { Component } from 'react';
import './Bot.css'

class DynamicBot extends Component {
    
    
    constructor(props) {
        super(props);
        this.state = {
            panel: undefined
        };
    }
    
    componentDidMount() {
    }
    
    
    componentWillUnmount() {
        
    }
  

    getPrice() {
        var details = this.props.details
        if (details.symbol.includes("GM")) {
            return this.numberWithCommas(details.currentCycle.currentOrderBooks[details.symbol.replace("/", "")].bids[0].price, 6)
        }
        if (details.indexConfig !== undefined) {
            if (details.symbol.includes("JPY")) {
                return this.numberWithCommas(details.currentCycle.currentOrderBooks[details.symbol.replace("/", "")].bids[0].price, 3)
            } else {
                return this.numberWithCommas(details.currentCycle.currentOrderBooks[details.symbol.replace("/", "")].bids[0].price, 5)
            }
        }
        return true ? details.currentCycle.currentOrderBooks[details.symbol.replace("/", "")].bids[0].price : details.currentCycle.currentOrderBooks[details.symbol.replace("/", "")].asks[0].price
    }

    getPriceExchange() {
        var details = this.props.details
        return true ? details.currentCycle.currentOrderBooks[details.symbol.replace("/", "")].bids[0].exchange : details.currentCycle.currentOrderBooks[details.symbol.replace("/", "")].asks[0].exchange
    }

    getSpread() {
        var details = this.props.details
        var spread = details.currentCycle.currentOrderBooks[details.symbol.replace("/", "")].asks[0].price - details.currentCycle.currentOrderBooks[details.symbol.replace("/", "")].bids[0].price
        if (details.symbol.includes('JPY')) {
            spread *= 100
        } else {
            spread *= 10000
        }
        spread = spread.toFixed(1)
        return spread
    }


    getUsdNotional() {
        var details = this.props.details

        var notional = 0
        details.currentCycle.positionLadder.forEach((pos) => {
            if (pos.currency !== "USD") {
                notional += Math.abs(pos.amountUsd)
            }
        })

        return notional
    }

    getDistance() {
        var details = this.props.details
        var notional = this.getUsdNotional()
        if (notional === 0) return 0
        var distance = (details.currentCycle.pnl / notional)*-1

        if (details.symbol.includes("JPY")) {
            distance *= 100
        } else {
            distance *= 10000
        }

        distance *= this.getPrice()
        
        return distance.toFixed(1)

    }

    getDirection() {
        var details = this.props.details
        return details.config.direction === "LONG" ? (<div className='direction-long'>LONG</div>) : (<div className='direction-short'>SHORT</div>)
    }

    getRealisedPnl() {
        var details = this.props.details
        var realised = 0
        details.completedCycles.forEach(cycle => {
            realised += cycle.pnl
        });



        return realised
    }

    getFees() {
        var details = this.props.details
        var fees = 0
        details.completedCycles.forEach(cycle => {
            fees += cycle.fees
        });
        fees += details.currentCycle.fees

        return fees
    }

    getCycles() {
        var details = this.props.details
        return details.completedCycles.length
    }

    getLevels() {
        
        var details = this.props.details
        return details.currentCycle.nextOrderIndex + " / " + details.config.orders.length
    }

    getUnrealised() {
        var details = this.props.details
        return details.currentCycle.pnl
    }

    numberWithCommas(x, decimals = 2) {
        return Number.parseFloat(x).toFixed(decimals).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }
    
    crossedWeekend(initialDate, hoursToAdd) {
        let startDate = new Date(initialDate);
        let initialDayOfWeek = startDate.getUTCDay();
        let initialHourOfDay = startDate.getUTCHours();
    
        startDate.setHours(startDate.getHours() + hoursToAdd);
        let finalDayOfWeek = startDate.getUTCDay();
        let finalHourOfDay = startDate.getUTCHours();
    
        // Constants for day of week
        const WEDNESDAY = 3, THURSDAY = 4, FRIDAY = 5, SATURDAY = 6, SUNDAY = 0, MONDAY = 1, TUESDAY = 2;
    
        // Check if started before or at Friday 20:00
        let startedBeforeWeekend = (initialDayOfWeek === WEDNESDAY || initialDayOfWeek === THURSDAY || initialDayOfWeek === FRIDAY) 
            || (initialDayOfWeek === FRIDAY && initialHourOfDay < 20);
    
        // Check if ended during or after Sunday 22:00
        let endedAfterWeekend = (finalDayOfWeek === SUNDAY || finalDayOfWeek === MONDAY || finalDayOfWeek === TUESDAY) 
            || (finalDayOfWeek === SUNDAY && finalHourOfDay >= 23);
    
        // Check if ended within the weekend timeframe (between Friday 20:00 and Sunday 22:00)
        let endedWithinWeekend = (finalDayOfWeek === SATURDAY) 
            || (finalDayOfWeek === FRIDAY && finalHourOfDay >= 20) 
            || (finalDayOfWeek === SUNDAY && finalHourOfDay < 23);
    
        return (startedBeforeWeekend && (endedAfterWeekend || endedWithinWeekend)) 
            || (!startedBeforeWeekend && endedWithinWeekend);
    }


    getTrades() {
        var details = this.props.details
        if (details.currentCycle.trades.length === 0) {
            return <div className='bot-no-trades'>No trades yet in this cycle</div>
        } else {
            var trades = []

            var index = 0
            var totalGap = 0

            var totalGapDiv;

            details.currentCycle.trades.forEach((trade) => {
                var diff;

                
                if (index > 0) {
                    var prevTrade = details.currentCycle.trades[index-1]
                    var pipdiff = Math.abs(prevTrade.price - trade.price)
                    if (details.symbol.includes("JPY")) {
                        pipdiff *= 100
                    } else {
                        pipdiff *= 10000
                    }
                    diff = <div className='bot-trades-trade-price'>Gap: <span>{this.numberWithCommas(pipdiff, 1)}</span> pips</div>
                    totalGap += pipdiff
                }
                trades.push(<div className='bot-trades-trade'>
                    <div className='bot-trades-trade-symbol'>{trade.symbol}</div>
                    <div className='bot-trades-trade-side'>Side: <span>{trade.side}</span></div>
                    <div className='bot-trades-trade-quantity'>Quantity: <span>{this.numberWithCommas(trade.quantity, 0)}</span></div>
                    <div className='bot-trades-trade-price'>Price: <span>{trade.price}</span></div>
                    {diff}
                    <div className='bot-trades-trade-date'>{new Date(trade.date).toUTCString().split(",")[1]}</div>

                </div>)

                index++
            })

            if (totalGap > 0) {
                totalGapDiv =  <div className='bot-trades-trade'>
                    <div className='bot-trades-trade-side'>Total difference: <span>{this.numberWithCommas(totalGap, 1)}</span></div>
                </div>

                trades.push(totalGapDiv)
            }

            return <div className='bot-trades'>{trades}</div>
        }
    }

    getPrevioudCycles() {

        var cycles = []
        var details = this.props.details
        if (details.completedCycles.length == 0) {
            return <div className='bot-no-trades'>No completed cycles yet</div>
        } else {
            details.completedCycles.forEach((cycle) => {
                var cycleDiv = <div className='bot-trades-trade'>

                    <div className='bot-trades-trade-side'>Start: <span>{new Date(cycle.start).toUTCString().split(",")[1]}</span></div>
                    <div className='bot-trades-trade-side'>End: <span>{new Date(cycle.end).toUTCString().split(",")[1]}</span></div>
                    <div className='bot-trades-trade-side'>Pnl: <span>{this.numberWithCommas(cycle.pnl, 2)}</span></div>


                </div>
                cycles.push(cycleDiv)

            }) 

        }


        return <div className='bot-prev-cycles'>{cycles}</div>
    }

    getGrid() {
        var cycles = []
        var details = this.props.details

        var sellLines = []
        var buyLines = []

        if (details.config.buy)

        var initialPrice = details.currentCycle.initialPrice

        return <div className="bot-grid">
            <div className='bot-grid-sell'>Sell Lines</div>
            
            <div>{sellLines}</div>

            <div className='bot-grid-buy'>Buy Lines</div>
            <div>{buyLines}</div>
        </div>
    }

    openPanel(name) {
        if (this.state.panel === name) {
            this.closePanel()
        } else {
            this.setState({
                panel:name
            })
        }
    }

    closePanel() {
        this.setState({
            panel:undefined
        })
    }

    render() {
        var ladderuPnl;
        var ladderPnl;
        var details = this.props.details
        if (details.currentCycle.currentOrderBooks[details.symbol.replace("/", "")] === undefined
            || details.currentCycle.currentOrderBooks[details.symbol.replace("/", "")] === null) {
                return (<div>Loading...</div>)
            } 

        if (details.currentCycle.indexConfig != null) {
            ladderuPnl =  <div className='bot-row bot-realised'>🪜 Unrealised <span className={details.currentCycle.indexPositionLadderPnl >= 0 ? 'pnl-green' : 'pnl-red'} >{this.numberWithCommas(details.currentCycle.indexPositionLadderPnl, 2)}</span></div>
        }

        if (details.currentCycle.indexConfig != null) {
            var pnl = 0
            details.completedCycles.forEach(element => {
                pnl += element.indexPositionLadderPnl
            });
            ladderPnl =  <div className='bot-row bot-realised'>🪜 Pnl <span className={pnl >= 0 ? 'pnl-green' : 'pnl-red'} >{this.numberWithCommas(pnl, 2)}</span></div>
        }

        var colour = this.props.colour

        var botMaximumTime = Number.parseFloat(details.groupMaximumCycleLengthHours)


        var timeLeftDiv;

        if (botMaximumTime > 0 && details.currentCycle.startCycleLengthCountdown !== 0) {
            var cycleEndTime = details.currentCycle.startCycleLengthCountdown + (botMaximumTime*60*60*1000)

            var timeLeft = (cycleEndTime - Date.now())/1000.0/60.0/60.0;
            


            if (details.groupMaximumCycleLengthHoursWeekendExtension > 0) {
                if (this.crossedWeekend(details.currentCycle.start, botMaximumTime)) {
                    timeLeft += details.groupMaximumCycleLengthHoursWeekendExtension;
                }    
            }

            timeLeft =  timeLeft.toFixed(1)

            timeLeftDiv = <div className='bot-row bot-remaining'>Remaining: <b>{timeLeft}</b> hours</div>

            

        }

        var currentLevel = "Level 0";
        if (details.currentCycle.currentLevel < 0) {
            currentLevel = "" + Math.abs(details.currentCycle.currentLevel) + " sell side level"
        } else if (details.currentCycle.currentLevel > 0) {
            currentLevel = "" + Math.abs(details.currentCycle.currentLevel) + " buy side level"
        }

        var resumeTime;

        if (details.currentCycle.resumeTime > new Date().getTime()) {
            resumeTime = <div className='bot-row bot-config'>Resume: {new Date(details.currentCycle.resumeTime).toUTCString().split(",")[1]}</div>

        }

        var openPanel;

        if (this.state.panel === "trades") {
            openPanel = <div className='bot-panel'>{this.getTrades()}
                <div className='bot-panel-close' onClick={()=>{this.closePanel()}}>X</div>
            </div>
        } else if (this.state.panel === "cycles") {
            openPanel = <div className='bot-panel'>{this.getPrevioudCycles()}
                            <div className='bot-panel-close' onClick={()=>{this.closePanel()}}>X</div>

            </div>
        }  else if (this.state.panel === "grid") {
            openPanel = <div className='bot-panel'>{this.getGrid()}
                            <div className='bot-panel-close' onClick={()=>{this.closePanel()}}>X</div>

            </div>
        }

        return (
            <div className={`bot bot-colour-${colour}` }>
                <div className='bot-left'>
                    <div className='bot-row bot-symbol'><img src={details.symbol.toLowerCase().replace("/", "") + ".svg"} />{details.symbol}</div>
                    <div className='bot-row bot-price'>{this.getPrice()} <span>{this.getPriceExchange()}</span></div>
                    <div className='bot-row bot-spread'>Spread {this.getSpread()}</div>
                    <div className='bot-row bot-spread'>Distance {this.getDistance()}</div>
                    <div className='bot-row bot-spread'>Initial {details.currentCycle.initialPrice}</div>
                    <div className='bot-row bot-state'>{details.state !== "RUNNING" ? details.state : ""}</div>

                    <div className='bot-row bot-config'>{details.config.name.replace('configs/', '')} / {details.config.divider}</div>

                    <div className='bot-row bot-config'>{new Date(details.currentCycle.start).toUTCString().split(",")[1]}</div>
                   
                    {timeLeftDiv}

                    {resumeTime}
                </div>
                <div className='bot-right'>
                <div className='bot-row bot-position'>Position <span>{this.numberWithCommas(details.currentCycle.positionAmount, 0)}</span></div>
                
                <div className='bot-row bot-realised'>Realised <span className={this.getRealisedPnl() >= 0 ? 'pnl-green' : 'pnl-red'} >{this.numberWithCommas(this.getRealisedPnl(), 2)}</span></div>
                    <div className='bot-row bot-fees'>Fees <span>{this.numberWithCommas(this.getFees(), 2)}</span></div>
                    <div className='bot-row bot-realised'>Unrealised <span className={this.getUnrealised() >= 0 ? 'pnl-green' : 'pnl-red'} >{this.numberWithCommas(this.getUnrealised(), 2)}</span></div>


                <div className='bot-row bot-position'>{currentLevel}</div>

                    {ladderPnl}
                    {ladderuPnl}
                    <div className='bot-row bot-position'>{this.getCycles()} cycles</div>

                </div>

            <div className='bot-tabs'>
                <div className='bot-tab' onClick={() => {this.openPanel('trades')}}>Trades</div>
                <div className='bot-tab' onClick={() => {this.openPanel('cycles')}}>Cycles</div>

            </div>

            {openPanel}


            <div className='bot-row bot-id'>{details.id}</div>

                
                
            </div>
            );
        }
    }
    
    export default DynamicBot;