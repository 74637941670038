import React, { Component } from 'react';
import './Dashboard.css'
import PositionLadder from './Components/PositionLadder'
import Bot from './Components/Bot';
import DynamicBot from './Components/DynamicBot';
import Config from './Components/Config';
import Prices from './Components/Prices';
import FvgBot from './Components/FvgBot';
import Home from './Components/Home';
import History from './Components/History';
import ReactLoading from "react-loading";
import Ranks from './Components/Ranks';

class Dashboard extends Component {
    
    
    constructor(props) {
        super(props);
        this.state = {
            positionLadder: undefined,
            positionLadderCurrent: undefined,
            positionLadderGroups: undefined,
            positionLadderGroupsAll: undefined,
            runningBots: undefined,
            prices: undefined,
            nop: undefined,
            dailyReports: undefined,
            serverStats: undefined,
            latestTrades: undefined,
            todaysVolume: undefined,
            todaysFees: undefined,
            todaysTradeNumber: undefined,

            historicExchangeRanks: undefined,
            symbolExchangeRanks: undefined,

            exchangeAcceptance: undefined,
            exchangeAcceptanceHour: undefined,

            showConfig:false,
            groupIds: [],
            groupConfigs: [],
            selectedTab: "advanced"
        }
    }
    
    componentDidMount() {
        console.log("mount")
        this.load()
        this.refreshGroupIds()
    }
    
    
    componentWillUnmount() {
        
    }

    refreshGroupIds() {
        if (this.state.groupIds.length === 0) {
            setTimeout(() => { this.refreshGroupIds() }, 500)
            return;
        }

        var timeout = 1000

        const currentDate = new Date(); // Get the current date and time

        // Check if current day and time fall into the specified conditions
        if ((currentDate.getDay() === 5 && currentDate.getHours() >= 22) || // Friday after 22:00
            currentDate.getDay() === 6 || // All day Saturday
            (currentDate.getDay() === 0 && currentDate.getHours() < 21)) { // Sunday before 22:00

            timeout = 60000
         }


            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ groupIds: this.state.groupIds })
            };
            fetch('https://api.goodmarkets.com/Trade/GetGroupConfigs', requestOptions)
                .then(response => response.json())
                .then(data => 
                    {
                        this.setState({ groupConfigs: data })

                        setTimeout(() => { this.refreshGroupIds() }, timeout)
                    }
            );
    }


    load() {


        var timeout = 300

        const currentDate = new Date(); // Get the current date and time

        // Check if current day and time fall into the specified conditions
        if ((currentDate.getDay() === 5 && currentDate.getHours() >= 22) || // Friday after 22:00
            currentDate.getDay() === 6 || // All day Saturday
            (currentDate.getDay() === 0 && currentDate.getHours() < 22)) { // Sunday before 22:00

            timeout = 60000
         }

        fetch('https://api.goodmarkets.com/Trade/DashboardInfoV2')
        .then(response => response.json())
        .then(data => 
            {

                this.setState({
                    runningBots : data.runningBotsSummary,
                    positionLadder: data.positionLadder,
                    positionLadderCurrent: data.positionLadderCurrent,
                    positionLadderGroups: data.positionLadderGroups,
                    positionLadderGroupsAll: data.positionLadderGroupsAll,
                    nop: data.nop,
                    prices: data.prices,
                    dailyReports: data.dailyReports,
                    serverStats: data.serverStats,
                    latestTrades: data.latestTrades,
                    todaysVolume: data.todaysVolume,
                    todaysFees: data.todaysFees,
                    todaysTradeNumber: data.todaysTradeNumber,

                    historicExchangeRanks: data.historicExchangeRanks,
                    symbolExchangeRanks: data.symbolExchangeRanks,
                    exchangeAcceptance: data.exchangeAcceptance,
                    exchangeAcceptanceHour: data.exchangeAcceptanceHour
                })
                
                setTimeout(() => { this.load() }, timeout)

            })
            .catch((error) => {
                console.log(error)

                setTimeout(() => { this.load() }, 1000)

              });;
         
              
    }



    toggleConfig() {
        this.setState({
            showConfig: !this.state.showConfig
        })
    }
    
    logout() {
        localStorage.clear()
        window.location.reload();
    }

    selectTab(name) {
        if (name === "logout") {
            this.logout();
            return;
        }
        this.setState({
            selectedTab: name
        });
    }
    
    getPriceForSymbol(symbol) {
        if (this.state.prices[symbol] === undefined) {
            return 0;
        }
        return this.state.prices[symbol].asks[0].price;
    }

    convertValue(symbol, value) {

        if (!symbol.includes('/USD')) {
            var quote = symbol.split("/")[1];
            if (this.getPriceForSymbol(quote + "/USD") !== 0) {
                value *= this.getPriceForSymbol(quote + "/USD")
            } else if (this.getPriceForSymbol("USD/" + quote) !== 0) {
                value /= this.getPriceForSymbol("USD/" + quote)
            }
        }
        return value
    }

    numberWithCommas(x, decimals = 2) {
        return Number.parseFloat(x).toFixed(decimals).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    render() {


        var showHistory = true

        var showLatestTrades = true

        var showRanks = true

        var historyTab

        if (showHistory) {
            historyTab = <div onClick={() => {this.selectTab("history")}} className={'header-menu-item ' + (this.state.selectedTab === "history" ? 'header-menu-item-selected' : "") }>History</div>
        }

        var ranksRab

        if (showRanks) {
            ranksRab = <div onClick={() => {this.selectTab("ranks")}} className={'header-menu-item ' + (this.state.selectedTab === "ranks" ? 'header-menu-item-selected' : "") }>Ranks</div>
        }

        var logoutTab = <div onClick={() => {this.selectTab("logout")}} className={'header-menu-item ' + (this.state.selectedTab === "logout" ? 'header-menu-item-selected' : "") }>Logout</div>

        if (this.state.selectedTab === "prices") {
            return (
            <div className='dashboard'>
            <div className='header'>
                
            
            <div onClick={() => {this.selectTab("advanced")}} className={'header-menu-item ' + (this.state.selectedTab === "advanced" ? 'header-menu-item-selected' : "") }>Home</div>
            <div onClick={() => {this.selectTab("prices")}} className={'header-menu-item ' + (this.state.selectedTab === "prices" ? 'header-menu-item-selected' : "") }>Prices</div>
                {historyTab}

                {ranksRab}

                {logoutTab}
                </div>
            <div className='clear'></div>

                <Prices prices={this.state.prices} />
            </div>)
        } 

        if (this.state.runningBots === undefined) {
            return (<div className='loading'><ReactLoading
                type={"bars"}
                color={"#03fc4e"}
                height={100}
                width={100}
              /></div>)
        }
        
        let config = []
        if (this.state.showConfig) {
            config.push(<Config toggle={() => {this.toggleConfig()}} />)
        }

        var groupIds = []

        var groupPairs = {};

        var groupTps = {};

        let bots = []

        let colours = {}

        var colourIndex = 1

        var totalVolume = 0;
        var totalFees = 0

        var currentCycleLockedPnl = 0;

        this.state.runningBots.sort(
            function(a,b) { 
                if (a === undefined || b === undefined) { 
                    return 0 
                }

                if (a.groupId !== null && b.groupId === null) {
                    return -1
                }

                if (a.groupId === null && b.groupId !== null) {
                    return 1
                }

                if (a.groupId !== null && b.groupId !== null) {
                    if (a.groupId !== undefined && b.groupId !== undefined) {
                        if (a.groupId !== b.groupId) {
                            return a.groupId.localeCompare(b.groupId)
                        }
                    }
                }

                if (a.symbol === b.symbol) {
                    return a.id.localeCompare(b.id)
                }
                return a.symbol.localeCompare(b.symbol)
            }
                ).forEach((bot) => {

                    var details = bot
                    var fees = 0

                    if (bot.type !== "fvgtrader") {
                        fees += details.completedCycleFees

                       
                        if (details.currentCycle !== undefined) {
                            fees += details.currentCycle.fees
                        }
                    }

                    totalFees += fees

                    totalVolume += bot.totalVolume

                    var colour = 0
                    if (bot.groupId !== undefined) {
                        if (colours[bot.groupId] === undefined) {
                            colours[bot.groupId] = colourIndex
                            colourIndex++
                        }
                        colour = colours[bot.groupId]

                        if (!groupIds.includes(bot.groupId)) {
                            groupIds.push(bot.groupId)
                        }
                        if (groupPairs[bot.groupId] === undefined) {
                            groupPairs[bot.groupId] = bot.symbol
                        } else {
                            groupPairs[bot.groupId] +=  " " + bot.symbol
                        }

                        if (groupTps[bot.groupId] === undefined) {
                            groupTps[bot.groupId] = { groupTakeProfit : bot.groupTakeProfit, groupTakeProfitPercent : bot.groupTakeProfitPercent, groupMinimumTakeProfit: bot.groupMinimumTakeProfit }
                        }
                    }

                    if (bot.type !== "dynamicgrid") {
                        if (bot.type === "fvgtrader") {
                            bots.push(<FvgBot details={bot} toggle={this.toggleConfig} colour={colour} prices={this.state.prices} />)
                        } else {
                            bots.push(<Bot details={bot} toggle={this.toggleConfig} colour={colour} prices={this.state.prices} />)
                            currentCycleLockedPnl += this.convertValue(bot.symbol, bot.currentCycle.realisedPnl);
                        }
                    } else {
                        bots.push(<DynamicBot details={bot} toggle={this.toggleConfig} colour={colour} />)
                    }
                })
            

                groupIds.sort()
        this.state.groupIds = groupIds;

        if (this.state.selectedTab === "history") {
            return (
                <div className='dashboard'>
            <div className='header'>
                
                
            <div onClick={() => {this.selectTab("advanced")}} className={'header-menu-item ' + (this.state.selectedTab === "advanced" ? 'header-menu-item-selected' : "") }>Home</div>
            <div onClick={() => {this.selectTab("prices")}} className={'header-menu-item ' + (this.state.selectedTab === "prices" ? 'header-menu-item-selected' : "") }>Prices</div>

            {historyTab}

            {ranksRab}

            {logoutTab}
                </div>
            <div className='clear'></div>

                <History positionLadder={this.state.positionLadder} positionLadderCurrent={this.state.positionLadderCurrent} positionLadderGroups={this.state.positionLadderGroups} positionLadderGroupsAll={this.state.positionLadderGroupsAll} groupIds={groupIds} groupPairs={groupPairs} groupTps={groupTps} groupConfigs={this.state.groupConfigs} nop={this.state.nop}
                                totalVolume={totalVolume} totalFees={totalFees} currentCycleLockedPnl={currentCycleLockedPnl}
                                dailyReports={this.state.dailyReports}
                                serverStats={this.state.serverStats} />
            </div>

            )
        }

        if (this.state.selectedTab === "ranks") {
            return (
                <div className='dashboard'>
            <div className='header'>
                
                
            <div onClick={() => {this.selectTab("advanced")}} className={'header-menu-item ' + (this.state.selectedTab === "advanced" ? 'header-menu-item-selected' : "") }>Home</div>
            <div onClick={() => {this.selectTab("prices")}} className={'header-menu-item ' + (this.state.selectedTab === "prices" ? 'header-menu-item-selected' : "") }>Prices</div>

            {historyTab}

            {ranksRab}

            {logoutTab}
                </div>
            <div className='clear'></div>

                <Ranks historicExchangeRanks={this.state.historicExchangeRanks}
                        symbolExchangeRanks={this.state.symbolExchangeRanks}
                        exchangeAcceptance={this.state.exchangeAcceptance}
                        exchangeAcceptanceHour={this.state.exchangeAcceptanceHour}
                />
            </div>

            )
        }

        var latestTrades;

        if (showLatestTrades && this.state.latestTrades !== undefined) {
            var trades = []

            this.state.latestTrades.forEach((trade) => {
                trades.push(<div className='latestTrade'>
                    <div className='latestTrade-symbol'><img src={trade.symbol.toLowerCase().replace("/", "") + ".svg"} /> {trade.symbol}</div>
                    <div className='latestTrade-side'>{trade.side}</div>
                    <div className='latestTrade-quantity'>{this.numberWithCommas(trade.quantity, 0)}</div>
                    <div className='latestTrade-price'>{trade.price}</div>
                    <div className='latestTrade-notional'>${this.numberWithCommas(trade.notional, 2)}</div>
                    <div className='latestTrade-fee'>Fee ${trade.fee}</div>
                    <div className='latestTrade-date'>{new Date(trade.date).toUTCString().split(",")[1]}</div>
                    <div className='latestTrade-exchange'>{trade.exchange}</div>

                </div>)
            })

            latestTrades = <div className='latestTrades'>
                     <div className='home-grid-panel-title '> Latest Trades</div>

                {trades}</div>

        }
        
        return (
            <div className='dashboard'>
                <div className='header'>
                    
                    
                <div onClick={() => {this.selectTab("advanced")}} className={'header-menu-item ' + (this.state.selectedTab === "advanced" ? 'header-menu-item-selected' : "") }>Home</div>
            <div onClick={() => {this.selectTab("prices")}} className={'header-menu-item ' + (this.state.selectedTab === "prices" ? 'header-menu-item-selected' : "") }>Prices</div>
            {historyTab}

            {ranksRab}

            {logoutTab}
                </div>
            <div className='clear'></div>
            <div className='dashboard-container-global'>
                <div>
                    <Home positionLadder={this.state.positionLadder} positionLadderCurrent={this.state.positionLadderCurrent} positionLadderGroups={this.state.positionLadderGroups} positionLadderGroupsAll={this.state.positionLadderGroupsAll} groupIds={groupIds} groupPairs={groupPairs} groupTps={groupTps} groupConfigs={this.state.groupConfigs} nop={this.state.nop}
                                        totalVolume={totalVolume} totalFees={totalFees} currentCycleLockedPnl={currentCycleLockedPnl}
                                        dailyReports={this.state.dailyReports}
                                        serverStats={this.state.serverStats}
                                        todaysVolume={this.state.todaysVolume}
                                        todaysFees={this.state.todaysFees}
                                        todaysTradeNumber={this.state.todaysTradeNumber}
                                        />

                        {latestTrades}

                        <PositionLadder positionLadder={this.state.positionLadder} positionLadderCurrent={this.state.positionLadderCurrent} positionLadderGroups={this.state.positionLadderGroups} positionLadderGroupsAll={this.state.positionLadderGroupsAll} groupIds={groupIds} groupPairs={groupPairs} groupTps={groupTps} groupConfigs={this.state.groupConfigs} nop={this.state.nop}
                                        totalVolume={totalVolume} totalFees={totalFees} currentCycleLockedPnl={currentCycleLockedPnl}
                                        dailyReports={this.state.dailyReports}
                                        />
                 </div>
                
                <div className='dashboard-container'>
                

                <div className='clear'></div>
                {bots}
                </div>
            </div>


            {config}

            </div>
            );
        }
    }
    
    export default Dashboard;