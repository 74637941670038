import React, { Component } from 'react';
import './Bot.css'
import ReactLoading from "react-loading";

class Bot extends Component {
    
    
    constructor(props) {
        super(props);
        this.state = {
            panel: undefined,
            completedCycles: undefined,
            trades: undefined
        };
    }
    
    componentDidMount() {
    }
    
    
    componentWillUnmount() {
        
    }
  

    getPrice() {
        var details = this.props.details
        if (details.symbol.includes("GM")) {
            return this.numberWithCommas(details.currentCycle.currentOrderBook.bids[0].price, 6)
        }
        if (details.indexConfig !== undefined) {
            if (details.symbol.includes("JPY")) {
                return this.numberWithCommas(details.currentCycle.currentOrderBook.bids[0].price, 3)
            } else {
                return this.numberWithCommas(details.currentCycle.currentOrderBook.bids[0].price, 5)
            }
        }
        return details.config.direction === "LONG" ? details.currentCycle.currentOrderBook.bids[0].price : details.currentCycle.currentOrderBook.asks[0].price
    }

    getPriceExchange() {
        var details = this.props.details
        return details.config.direction === "LONG" ? details.currentCycle.currentOrderBook.bids[0].exchange : details.currentCycle.currentOrderBook.asks[0].exchange
    }

    getSpread() {
        var details = this.props.details
        var spread = details.currentCycle.currentOrderBook.asks[0].price - details.currentCycle.currentOrderBook.bids[0].price
        if (details.symbol.includes('JPY')) {
            spread *= 100
        } else {
            spread *= 10000
        }
        spread = spread.toFixed(1)
        return spread
    }

    getDistance() {
        var details = this.props.details

        var spread = details.currentCycle.averageEntryPrice - this.getPrice()
        if (details.config.direction === "SHORT" ) {
            spread = this.getPrice() -  details.currentCycle.averageEntryPrice
        }
        if (details.symbol.includes('JPY')) {
            spread *= 100
        } else {
            spread *= 10000
        }
        spread = spread.toFixed(1)
        return spread

    }

    getDirection() {
        var details = this.props.details
        return details.config.direction === "LONG" ? (<div className='direction-long'>LONG</div>) : (<div className='direction-short'>SHORT</div>)
    }

    getRealisedPnl() {
        var details = this.props.details
        var realised = details.completedRealisedPnl
       /*
        details.completedCycles.forEach(cycle => {
            realised += cycle.realisedPnl
        });
        */

        realised += details.currentCycle.realisedPnl

        if (!details.symbol.includes('/USD')) {
            var quote = details.symbol.split("/")[1];
            if (this.getPriceForSymbol(quote + "/USD") !== 0) {
                realised *= this.getPriceForSymbol(quote + "/USD")
            } else if (this.getPriceForSymbol("USD/" + quote) !== 0) {
                realised /= this.getPriceForSymbol("USD/" + quote)
            }
        }

        return realised
    }

    getFees() {
        var details = this.props.details
        var fees = details.completedCycleFees
        /*
        details.completedCycles.forEach(cycle => {
            fees += cycle.fees
        });
        */
        fees += details.currentCycle.fees

        return fees
    }

    getCycles() {
        var details = this.props.details
        return details.completedCyclesNumber
    }

    getLevels() {
        var details = this.props.details
        return details.currentCycle.nextOrderIndex + " / " + details.config.orders.length
    }

    getPriceForSymbol(symbol) {
        if (this.props.prices[symbol] === undefined) {
            return 0;
        }
        return this.props.prices[symbol].asks[0].price;
    }

    getUnrealised() {
        var details = this.props.details

        var amount = Math.abs(details.currentCycle.positionAmount)
        var unrealised = 0
        if (details.currentCycle.positionAmount < 0) {
            unrealised = details.currentCycle.averageEntryPrice*amount - this.getPrice()*amount
        } else {
            unrealised = this.getPrice()*amount - details.currentCycle.averageEntryPrice*amount
        }

        if (!details.symbol.includes('/USD')) {
            var quote = details.symbol.split("/")[1];
            if (this.getPriceForSymbol(quote + "/USD") !== 0) {
                unrealised *= this.getPriceForSymbol(quote + "/USD")
            } else if (this.getPriceForSymbol("USD/" + quote) !== 0) {
                unrealised /= this.getPriceForSymbol("USD/" + quote)
            }
        }
        return unrealised
    }

    convertValue(value) {
        var details = this.props.details

        if (!details.symbol.includes('/USD')) {
            var quote = details.symbol.split("/")[1];
            if (this.getPriceForSymbol(quote + "/USD") !== 0) {
                value *= this.getPriceForSymbol(quote + "/USD")
            } else if (this.getPriceForSymbol("USD/" + quote) !== 0) {
                value /= this.getPriceForSymbol("USD/" + quote)
            }
        }
        return value
    }

    numberWithCommas(x, decimals = 2) {
        return Number.parseFloat(x).toFixed(decimals).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }
    
    crossedWeekend(initialDate, hoursToAdd) {
        let startDate = new Date(initialDate);
        let initialDayOfWeek = startDate.getUTCDay();
        let initialHourOfDay = startDate.getUTCHours();
    
        startDate.setHours(startDate.getHours() + hoursToAdd);
        let finalDayOfWeek = startDate.getUTCDay();
        let finalHourOfDay = startDate.getUTCHours();
    
        // Constants for day of week
        const WEDNESDAY = 3, THURSDAY = 4, FRIDAY = 5, SATURDAY = 6, SUNDAY = 0, MONDAY = 1, TUESDAY = 2;
    
        // Check if started before or at Friday 20:00
        let startedBeforeWeekend = (initialDayOfWeek === WEDNESDAY || initialDayOfWeek === THURSDAY || initialDayOfWeek === FRIDAY) 
            || (initialDayOfWeek === FRIDAY && initialHourOfDay < 20);
    
        // Check if ended during or after Sunday 22:00
        let endedAfterWeekend = (finalDayOfWeek === SUNDAY || finalDayOfWeek === MONDAY || finalDayOfWeek === TUESDAY) 
            || (finalDayOfWeek === SUNDAY && finalHourOfDay >= 23);
    
        // Check if ended within the weekend timeframe (between Friday 20:00 and Sunday 22:00)
        let endedWithinWeekend = (finalDayOfWeek === SATURDAY) 
            || (finalDayOfWeek === FRIDAY && finalHourOfDay >= 20) 
            || (finalDayOfWeek === SUNDAY && finalHourOfDay < 23);
    
        return (startedBeforeWeekend && (endedAfterWeekend || endedWithinWeekend)) 
            || (!startedBeforeWeekend && endedWithinWeekend);
    }


    getTrades() {
        var details = this.props.details

        if (this.state.trades === undefined) {
            fetch('https://api.goodmarkets.com/Trade/CurrentTrades/' + details.id)
                .then(response => response.json())
                .then(data => 
                    {

                        this.setState({
                            trades: data
                        })
                        

                    })
     

                return (<div className='loading-small'><ReactLoading
                        type={"bars"}
                        color={"#03fc4e"}
                        height={50}
                        width={50}
                    /></div>)
        }
        else if (this.state.trades.length === 0) {
            return <div className='bot-no-trades'>No trades yet in this cycle</div>
        } else {
            var trades = []


            trades.push(<div className='bot-trades-trade'>
                    <div className='bot-trades-trade-side'>Number of trades: <span>{this.numberWithCommas(this.state.trades.length, 0)}</span></div>

                </div>)

            var index = 0
            var totalGap = 0

            var totalGapDiv;

            this.state.trades.forEach((trade) => {
                var diff;

                
                if (index > 0) {
                    var prevTrade = this.state.trades[index-1]
                    var pipdiff = Math.abs(prevTrade.price - trade.price)
                    if (details.symbol.includes("JPY")) {
                        pipdiff *= 100
                    } else {
                        pipdiff *= 10000
                    }
                    diff = <div className='bot-trades-trade-price'>Gap: <span>{this.numberWithCommas(pipdiff, 1)}</span> pips</div>
                    totalGap += pipdiff
                }

                var realisedPnl;
                if (trade.realisedPnl != 0) {
                    realisedPnl = <div className='bot-trades-trade-price '>Pnl: <span className='pnl-green'>{this.numberWithCommas(this.convertValue(trade.realisedPnl), 2)}</span></div>

                }

                trades.push(<div className='bot-trades-trade'>
                    <div className='bot-trades-trade-symbol'>{trade.symbol}</div>
                    <div className='bot-trades-trade-side'>Side: <span>{trade.side}</span></div>
                    <div className='bot-trades-trade-quantity'>Quantity: <span>{this.numberWithCommas(trade.quantity, 0)}</span></div>
                    <div className='bot-trades-trade-price'>Price: <span>{trade.price}</span></div>
                    {realisedPnl}
                    {diff}
                    <div className='bot-trades-trade-date'>{new Date(trade.date).toUTCString().split(",")[1]}</div>

                </div>)

                index++
            })

            if (totalGap > 0) {
                totalGapDiv =  <div className='bot-trades-trade'>
                    <div className='bot-trades-trade-side'>Total difference: <span>{this.numberWithCommas(totalGap, 1)}</span></div>
                </div>

                trades.push(totalGapDiv)
            }

            return <div className='bot-trades'>{trades}</div>
        }
    }

    getPreviousCycles() {

        var cycles = []
        var details = this.props.details
        if (details.completedCyclesNumber === 0) {
            return <div className='bot-no-trades'>No completed cycles yet</div>
        } else {
            if (this.state.completedCycles === undefined) {

                fetch('https://api.goodmarkets.com/Trade/CompletedCycles/' + details.id)
                .then(response => response.json())
                .then(data => 
                    {

                        this.setState({
                            completedCycles: data
                        })
                        

                    })
     

                return (<div className='loading-small'><ReactLoading
                        type={"bars"}
                        color={"#03fc4e"}
                        height={50}
                        width={50}
                    /></div>)
            } else {
                this.state.completedCycles.forEach((cycle) => {
                    var cycleDiv = <div className='bot-trades-trade'>

                        <div className='bot-trades-trade-side'>Start: <span>{new Date(cycle.start).toUTCString().split(",")[1]}</span></div>
                        <div className='bot-trades-trade-side'>End: <span>{new Date(cycle.end).toUTCString().split(",")[1]}</span></div>
                        <div className='bot-trades-trade-side'>Pnl: <span className='pnl-green'>{this.numberWithCommas( this.convertValue(cycle.realisedPnl), 2)}</span></div>


                    </div>
                    cycles.push(cycleDiv)

                }) 
            }

        }


        return <div className='bot-prev-cycles'>{cycles}</div>
    }

    openPanel(name) {
        if (this.state.panel === name) {
            this.closePanel()
        } else {
            this.setState({
                panel:name
            })
        }
    }

    closePanel() {
        this.setState({
            panel:undefined
        })
    }

    render() {
        var ladderuPnl;
        var ladderPnl;
        var details = this.props.details
        if (details.currentCycle.currentOrderBook === undefined
            || details.currentCycle.currentOrderBook === null) {
                return (<div>Loading...</div>)
            } 

        if (details.currentCycle.indexConfig != null) {
            ladderuPnl =  <div className='bot-row bot-realised'>🪜 Unrealised <span className={details.currentCycle.indexPositionLadderPnl >= 0 ? 'pnl-green' : 'pnl-red'} >{this.numberWithCommas(details.currentCycle.indexPositionLadderPnl, 2)}</span></div>
        }

        if (details.currentCycle.indexConfig != null) {
            var pnl = 0
            details.completedCycles.forEach(element => {
                pnl += element.indexPositionLadderPnl
            });
            ladderPnl =  <div className='bot-row bot-realised'>🪜 Pnl <span className={pnl >= 0 ? 'pnl-green' : 'pnl-red'} >{this.numberWithCommas(pnl, 2)}</span></div>
        }

        var colour = this.props.colour

        var botMaximumTime = Number.parseFloat(details.groupMaximumCycleLengthHours)


        var timeLeftDiv;

        if (botMaximumTime > 0) {
            var cycleEndTime = details.currentCycle.start + (botMaximumTime*60*60*1000)

            var timeLeft = (cycleEndTime - Date.now())/1000.0/60.0/60.0;
            


            if (details.groupMaximumCycleLengthHoursWeekendExtension > 0) {
                if (this.crossedWeekend(details.currentCycle.start, botMaximumTime)) {
                    timeLeft += details.groupMaximumCycleLengthHoursWeekendExtension;
                }    
            }

            timeLeft =  timeLeft.toFixed(1)

            timeLeftDiv = <div className='bot-row bot-remaining'>Remaining: <b>{timeLeft}</b> hours</div>

            

        }

        var openPanel;

        if (this.state.panel === "trades") {
            openPanel = <div className='bot-panel'>{this.getTrades()}
                <div className='bot-panel-close' onClick={()=>{this.closePanel()}}>X</div>
            </div>
        } else if (this.state.panel === "cycles") {
            openPanel = <div className='bot-panel'>{this.getPreviousCycles()}
                            <div className='bot-panel-close' onClick={()=>{this.closePanel()}}>X</div>

            </div>
        }  else if (this.state.panel === "grid") {
            openPanel = <div className='bot-panel'>{this.getGrid()}
                            <div className='bot-panel-close' onClick={()=>{this.closePanel()}}>X</div>

            </div>
        }

        return (
            <div className={`bot bot-colour-${colour}` }>
                <div className='bot-left'>
                <div className='bot-row bot-symbol'><img src={details.symbol.toLowerCase().replace("/", "") + ".svg"} />{details.symbol}</div>
                    <div className='bot-row bot-price'>{this.getPrice()} <span>{this.getPriceExchange()}</span></div>
                    <div className='bot-row bot-spread'>Spread {this.getSpread()}</div>
                    <div className='bot-row bot-state'>{details.state !== "RUNNING" ? details.state : ""}</div>
                    <div className='bot-row bot-state'>{details.config.alternateDirection  ? "⇆ Alternating" : ""}</div>
                    <div className='bot-row bot-direction'>{this.getDirection()}</div>
                    <div className='bot-row bot-spread'>Distance {this.getDistance()}</div>
                    <div className='bot-row bot-config'>{details.config.name.replace('configs/', '').replace('.csv', '')}</div>
                    <div className='bot-row bot-config'>{new Date(details.currentCycle.start).toUTCString().split(",")[1]}</div>
                    <div className='bot-row bot-id'>{details.id}</div>
                    {timeLeftDiv}
                </div>
                <div className='bot-right'>
                    <div className='bot-row bot-position'>Position <span>{this.numberWithCommas(details.currentCycle.positionAmount, 0)}</span></div>
                    <div className='bot-row bot-position'>Avg Price <span>{this.numberWithCommas(details.currentCycle.averageEntryPrice, details.symbol.includes('JPY') ? 3 : 5)}</span></div>
                    <div className='bot-row bot-realised'>Total Realised <span className={this.getRealisedPnl() >= 0 ? 'pnl-green' : 'pnl-red'} >{this.numberWithCommas(this.getRealisedPnl(), 2)}</span></div>
                    <div className='bot-row bot-realised'>Cycle Realised <span className={ 'pnl-green'} >{this.numberWithCommas(this.convertValue(details.currentCycle.realisedPnl), 2)}</span></div>
                    <div className='bot-row bot-fees'>Fees <span>{this.numberWithCommas(this.getFees(), 2)}</span></div>
                    <div className='bot-row bot-realised'>Unrealised <span className={this.getUnrealised() >= 0 ? 'pnl-green' : 'pnl-red'} >{this.numberWithCommas(this.getUnrealised(), 2)}</span></div>
                    {ladderPnl}
                    {ladderuPnl}
                    <div className='bot-row bot-position'>{this.getCycles()} cycles</div>
                    <div className='bot-row bot-position'>Levels {this.getLevels()}</div>
                    <div className='bot-row bot-position'>Volume <b>{this.numberWithCommas(details.totalVolume, 0)}</b></div>
                </div>

                <div className='bot-tabs'>
                    <div className='bot-tab' onClick={() => {this.openPanel('trades')}}>Trades</div>
                    <div className='bot-tab' onClick={() => {this.openPanel('cycles')}}>Cycles</div>

                </div>

            {openPanel}
                
            </div>
            );
        }
    }
    
    export default Bot;