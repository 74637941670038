import React, { Component } from 'react';
import './PositionLadder.css'

class PositionLadder extends Component {
    
    
    constructor(props) {
        super(props);
        this.state = {
            showEdit: false,
            saving: false,
            editName: "",
            editId : ""
        };
    }
    
    componentDidMount() {
    }
    
    
    componentWillUnmount() {
        
    }
    
    numberWithCommas(x, decimals = 2) {
        return Number.parseFloat(x).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    nameClicked(groupId, groupName) {
        this.setState({
            showEdit: true,
            saving: false,
            editName: groupName,
            editId: groupId
        });

    }

    closeEdit() {
        this.setState({
            showEdit: false,
            saving: false,
            editName: "",
            editId: ""
        });
    }

    saveGroupName() {
        this.setState({
            saving: true
        });

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: this.state.editId, name: this.state.editName })
        };
        fetch('https://api.goodmarkets.com/Trade/UpdateGroup', requestOptions)
            .then(response => 
                {
                    this.closeEdit()
                }
        );

    }

    
    render() {
        
        let positions = []
        if (this.props.positionLadder === undefined) {
            return <div className='no-positions'>Loading position ladder...</div>
        }

        if (this.props.positionLadder.length === 0) {
            return <div className='no-positions'>No positions</div>
        }

        var currentCyclePnl = 0;

        if (this.props.positionLadderCurrent !== undefined) {
            if (this.props.positionLadderCurrent.length !== 0) {
                this.props.positionLadderCurrent.forEach((pos) => {
                    var position = pos
                    currentCyclePnl += position.amountUsd
                })
            }
        }

        var groupPnls = [];

        var groupAllPnls = [];

        var nonGroupCyclePnl = currentCyclePnl

        var nonGroupPnl = 0

        var pnl = 0

        var pastPnl = 0

        this.props.dailyReports.forEach((item) => {
            pastPnl += item.realisedPnl
        })


        var concentrationLimits = {
            "GBP" : [56, 49, 0],
            "EUR" : [56, 49, 0],
            "JPY" : [56, 49, 0],
            "AUD" : [36, 29, 0],
            "NZD" : [36, 29, 0],
            "CAD" : [36, 29, 0],
            "NOK" : [36, 29, 0],
            "SEK" : [36, 29, 0],
            "CHF" : [36, 29, 0],
            "USD" : [100, 100, 0],
        }
        

        this.props.positionLadder.sort(
            function(a,b) { 
                if (a === undefined || b === undefined) { 
                    return 0 
                }
                return a.currency.localeCompare(b.currency)
            }
                ).forEach((pos) => {
                    var position = pos


                    var concentration = Math.abs(position.amountUsd)*100/this.props.nop.limit

                    var conColor = "nop-green"
                    if (concentration > concentrationLimits[position.currency][0]) {
                        conColor = "nop-red"
                    } else if (concentration > concentrationLimits[position.currency][1]) {
                        conColor = "nop-orange"
                    } else if (concentration > concentrationLimits[position.currency][2]) {
                        conColor = "nop-green"
                    }

                    pnl += position.amountUsd
                    positions.push(
                        <div className='position'>
                            <div className='position-currency'>
                            {position.currency} (<span className={conColor}>{this.numberWithCommas(concentration, 1)}%</span>)
                            </div>

                            <div className='position-details'>
                                <div className='position-details-amount'>
                                    {this.numberWithCommas(position.amount, 0)}
                                </div>
                                <div className='position-details-amountusd'>
                                    {this.numberWithCommas(position.amountUsd, 0)} USD
                                </div>
                            </div>
                        </div>
                        )
                    });

            var realisedPnl = pnl - currentCyclePnl

            nonGroupPnl = realisedPnl

        if (this.props.positionLadderGroups !== undefined && this.props.positionLadderGroupsAll !== undefined ) {
            if (this.props.positionLadderGroups.length !== 0) {
                var groupConfigs = {}
                this.props.groupConfigs.forEach((obj) => {
                    groupConfigs[obj.id] = obj
                })
                
                var index = 1

                var i= 0
                this.props.positionLadderGroups.forEach((array) => {
                    var groupPnl = 0
                    array.forEach((pos) => {
                        var position = pos
                        groupPnl += position.amountUsd
                    })

                    nonGroupCyclePnl -= groupPnl

                    var groupTotalPnl = 0

                    var groupNotional = 0

                    if (this.props.positionLadderGroupsAll[i] === undefined) {
                        return;
                    }
                    this.props.positionLadderGroupsAll[i].forEach((pos) => {
                        var position = pos
                        groupTotalPnl += position.amountUsd
                        if (position.currency !== "USD") {
                            groupNotional += Math.abs(position.amountUsd)
                        }
                    })

                    nonGroupPnl -= (groupTotalPnl-groupPnl)

                    var tp = this.props.groupTps[this.props.groupIds[index-1]].groupTakeProfit
                    if (this.props.groupTps[this.props.groupIds[index-1]].groupTakeProfitPercent > 0) {
                        var notionalTp = groupNotional * this.props.groupTps[this.props.groupIds[index-1]].groupTakeProfitPercent
                        if (notionalTp < tp) {
                            tp = notionalTp
                        }
                    }

                    if (this.props.groupTps[this.props.groupIds[index-1]].groupMinimumTakeProfit > 0) {
                        if (tp < this.props.groupTps[this.props.groupIds[index-1]].groupMinimumTakeProfit) {
                            tp = this.props.groupTps[this.props.groupIds[index-1]].groupMinimumTakeProfit
                        }
                    }

                    
                    var groupId = this.props.groupIds[index-1]

                    var groupName = "Group " + index

                    if (groupConfigs[groupId] !== undefined) {
                        groupName = groupConfigs[groupId].name
                    }

                    groupPnls.push(<div><div className='position'></div>



                    <div className='position-pnl group-pnl'>
                        <div className='position-pnl-group-title' id={groupId + "-name"} onClick={()=>{this.nameClicked(groupId, groupName)}}>{groupName}</div>
                        <div className={'position-pnl-pnl ' + (groupPnl >= 0 ? 'pnl-green' : 'pnl-red')}>{this.numberWithCommas(Number.parseFloat(groupPnl).toFixed(2))}</div>
                        <div className={'position-pnl-pnl ' + ((groupTotalPnl-groupPnl) >= 0 ? 'pnl-green' : 'pnl-red') + ' group-realised-small'}>{this.numberWithCommas(Number.parseFloat(groupTotalPnl-groupPnl).toFixed(2))}</div>
                        <div className='position-group-id'>{this.props.groupIds[index-1]}</div>
                        <div className='position-group-pairs'>{this.props.groupPairs[this.props.groupIds[index-1]]}</div>
                        <div className='position-group-notional'>${this.numberWithCommas(groupNotional, 0)}</div>
                        <div className='position-group-notional'>TP: ${this.numberWithCommas(tp, 2)}</div>

                    </div></div>)

                    index++
                    i++
                })
            }
        }
        
        
        var editBox;

        if (this.state.showEdit) {
            editBox = <div className='editGroupName'>
                <div className='configClose' onClick={()=>{this.closeEdit()}}>x</div>
                <input className='groupNameInput' type='text' value={this.state.editName} onChange={(e) => {
                    this.setState({
                        editName: e.target.value
                    })
                     }} />
                <div></div>
                <div className='submitButtonSmall' onClick={() => { 
                    if (this.state.saving) {
                        return
                    }
                    this.saveGroupName()

                }}>{this.state.saving ? "Saving..." : "Save"}</div>
            </div>
        }


        var nopColor = "nop-green";
        if (this.props.nop.utilization >= 78) {
            nopColor = "nop-red";
        } else if (this.props.nop.utilization >= 59) {
            nopColor = "nop-orange";
        } else {
            nopColor = "nop-green";
        }

        var nop =   <div className='position-pnl'>
        <div className='position-pnl-title'>NOP</div>
        <div className={'position-pnl-pnl ' + nopColor}>{this.numberWithCommas(Number.parseFloat(this.props.nop.utilization).toFixed(2))}%</div>
    </div>

        var profitYard = 0
        if (this.props.totalVolume !== 0) {
            profitYard = realisedPnl / (this.props.totalVolume/1000000000.0)
        }

        if (profitYard < 0 )  profitYard = 0

                    
                    return (
                        <div className='position-ladder'>
                        <div className='position-ladder-title'>Position Ladder</div>
                        <div>
                        {positions}
                        <div className='position'></div>

                        {nop}
                        <div className='position'></div>

                   

                        <div className='position-pnl'>
                            <div className='position-pnl-title'>PNL</div>
                            <div className={'position-pnl-pnl ' + (pnl >= 0 ? 'pnl-green' : 'pnl-red')}>{this.numberWithCommas(Number.parseFloat(pnl).toFixed(2))}</div>
                        </div>

                        <div className='position'></div>

                        <div className='position-pnl'>
                            <div className='position-pnl-title'>Cycle PNL</div>
                            <div className={'position-pnl-pnl ' + (currentCyclePnl >= 0 ? 'pnl-green' : 'pnl-red')}>{this.numberWithCommas(Number.parseFloat(currentCyclePnl).toFixed(2))}</div>
                        </div>
                        <div className='position'></div>


                        <div className='position-pnl'>
                            <div className='position-pnl-title'>Realised PNL</div>
                            <div className={'position-pnl-pnl ' + (realisedPnl >= 0 ? 'pnl-green' : 'pnl-red')}>{this.numberWithCommas(Number.parseFloat(realisedPnl).toFixed(2))}</div>
                        </div>
                        <div className='position'></div>


                        <div className='position-pnl '>
                            <div className='position-pnl-title '> Pending PNL</div>
                            <div className={'position-pnl-pnl pnl-green' }>{this.numberWithCommas(Number.parseFloat(this.props.currentCycleLockedPnl).toFixed(2))}</div>
                        </div>

                        <div className='position'></div>

                        <div className='position-border'></div>


                        <div className='position-pnl '>
                            <div className='position-pnl-title '> Today's Realised</div>
                            <div className={'position-pnl-pnl pnl-green' }>{this.numberWithCommas((Number.parseFloat(this.props.currentCycleLockedPnl) + Number.parseFloat(realisedPnl) - pastPnl).toFixed(2))}</div>
                        </div>
                        <div className='position'></div>
                        <div className='position-pnl '>
                            <div className='position-pnl-title '> Total Realised</div>
                            <div className={'position-pnl-pnl pnl-green' }>{this.numberWithCommas((Number.parseFloat(this.props.currentCycleLockedPnl) + Number.parseFloat(realisedPnl)).toFixed(2))}</div>
                        </div>

                        <div className='position'></div>


                        <div className='position-pnl '>
                            <div className='position-pnl-title '> Current Unrealised</div>
                            <div className={'position-pnl-pnl ' + ((-Number.parseFloat(this.props.currentCycleLockedPnl) + Number.parseFloat(currentCyclePnl)) >= 0 ? ' pnl-green' : ' pnl-red') }>{this.numberWithCommas((-Number.parseFloat(this.props.currentCycleLockedPnl) + Number.parseFloat(currentCyclePnl)).toFixed(2))}</div>
                        </div>

                      
                        <div className='position'></div>

                        <div className='position-ladder-title'>Groups</div>


                        {groupPnls}


                        <div><div className='position'></div>

                    <div className='position-pnl group-pnl'>
                        <div className='position-pnl-title'>No Group</div>
                        <div className={'position-pnl-pnl ' + (nonGroupCyclePnl >= 0 ? 'pnl-green' : 'pnl-red')}>{this.numberWithCommas(Number.parseFloat(nonGroupCyclePnl).toFixed(2))}</div>
                        <div className={'position-pnl-pnl ' + ((nonGroupPnl) >= 0 ? 'pnl-green' : 'pnl-red') + ' group-realised-small'}>{this.numberWithCommas(Number.parseFloat(nonGroupPnl).toFixed(2))}</div>

                    </div></div>

                        </div>
                        {editBox}

                        </div>

                        
                        
                        );
                    }
                }
                
                export default PositionLadder;