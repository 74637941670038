import React, { Component } from 'react';
import './PositionLadder.css'
import './Home.css'
import { Chart } from "react-google-charts";


const optionsServerChart = {
    width: 450,
    height: 120,
    redFrom: 90,
    redTo: 100,
    yellowFrom: 85,
    yellowTo: 90,
    minorTicks: 5,
  };

export const optionsDailyPnlChart = {
    curveType: "function",
    legend: { position: 'none' },
    backgroundColor: 'transparent',
    titleTextStyle: {
        color: '#FF5733', // Example: Orange color for the title text
        fontSize: 20, // You can also set the font size
      },
      // Set horizontal axis text style
      hAxis: {
        textStyle: {
          color: '#fff', // Example: Pink color for horizontal axis text
        }
      },
      // Set vertical axis text style
      vAxis: {
        textStyle: {
          color: '#64FA64', // Example: Green color for vertical axis text
        }
      },
  
      // Set line colors for the chart
      colors: ['#64FA64'],
};

class Home extends Component {
    
   
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    componentDidMount() {
    }
    
    
    componentWillUnmount() {
        
    }
    
    numberWithCommas(x, decimals = 2) {
        return Number.parseFloat(x).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    getServerData() {
        if (this.props.serverStats !== undefined) {
            return [
            ["Label", "Value"],
            ["Memory", (100.0*(this.props.serverStats[0].memoryUsageMb)/(this.props.serverStats[0].memoryFreeMb + this.props.serverStats[0].memoryUsageMb))],
            ["CPU", (Math.min(this.props.serverStats[0].cpuLoad*100.0, 80))],
            ["Threads", this.props.serverStats[0].threads],
            ];
        } else {
            return [
                ["Label", "Value"],
                ["Memory", 0],
                ["CPU", 0],
                ["Threads", 0],

                ];
        }
      }

      getDailyPnlData() {
        var result = []
        result.push(["Date", "Realised $"])
        this.props.dailyReports.forEach((item) => {
            result.push([item.date, item.realisedPnl])
        })
        return result;
      }

      getDailyVolumeData() {
        var result = []
        result.push(["Date", "Volume in $"])
        this.props.dailyReports.forEach((item) => {
            result.push([item.date, item.volume])
        })
        return result;
      }

      getDailyPnlEquityData() {
        var result = []
        result.push(["Date", "% return on equity"])
        this.props.dailyReports.forEach((item) => {
            result.push([item.date, (item.realisedPnl/item.equity)*100])
        })
        return result;
      }

    render() {
        


        var currentCyclePnl = 0;

        if (this.props.positionLadderCurrent !== undefined) {
            if (this.props.positionLadderCurrent.length !== 0) {
                this.props.positionLadderCurrent.forEach((pos) => {
                    var position = pos
                    currentCyclePnl += position.amountUsd
                })
            }
        }


        var nonGroupPnl = 0

        var pnl = 0

        this.props.positionLadder.sort(
            function(a,b) { 
                if (a === undefined || b === undefined) { 
                    return 0 
                }
                return a.currency.localeCompare(b.currency)
            }
                ).forEach((pos) => {
                    var position = pos
                    pnl += position.amountUsd
                    
                    });

        var pastPnl = 0

        var lastEquity = 1
        var lastRealised = 0
        this.props.dailyReports.forEach((item) => {
            pastPnl += item.realisedPnl
            lastEquity = item.equity
            lastRealised = item.realisedPnl
        })

        lastEquity = 1200000

        var realisedPnl = pnl - currentCyclePnl

        var nopColor = "nop-green";
        if (this.props.nop.utilization >= 78) {
            nopColor = "nop-red";
        } else if (this.props.nop.utilization >= 59) {
            nopColor = "nop-orange";
        } else {
            nopColor = "nop-green";
        }



        var todaysRealised = Number.parseFloat(this.props.currentCycleLockedPnl) + Number.parseFloat(realisedPnl) - pastPnl

        var swapProfit = Number.parseFloat(this.props.dailyReports[this.props.dailyReports.length - 1].swapProfit)
        var swapProfitYesterday = Number.parseFloat(this.props.dailyReports[this.props.dailyReports.length - 2].swapProfit)
       
                   
        
        const now = new Date(); // Current date and time

        // Set the time zone to UTC and get the current hour and minute
        const currentUTCHour = now.getUTCHours();
        const currentUTCMinutes = now.getUTCMinutes();
        const currentUTCDay = now.getUTCDay(); // Days are 0 (Sunday) - 6 (Saturday)

        // Calculate start date
        const startDate = new Date(now);
        startDate.setUTCHours(22, 0, 0, 0); // Set to 22:00 UTC
        if (currentUTCHour > 22 || (currentUTCHour === 22 && currentUTCMinutes > 0)) {
          // If it's past 22:00, start date is today
          startDate.setUTCDate(startDate.getUTCDate());
        } else {
          // Otherwise, start date is yesterday
          startDate.setUTCDate(startDate.getUTCDate() - 1);
        }
      
        // Set the end date to today at 20:50 UTC
        const endDate = new Date(now);
        if (endDate.getUTCHours() >= 22) {
            endDate.setUTCDate(endDate.getUTCDate() + 1);
        }
        endDate.setUTCHours(20, 50, 0, 0);
      
        // Calculate the difference in milliseconds
        const timeDiff = endDate - now;
        
        // Convert time difference to hours, minutes, and seconds
        const seconds = Math.floor((timeDiff / 1000) % 60);
        const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
        const hours = Math.floor((timeDiff / 1000 / 60 / 60));
      

        const dayStart = startDate.getDate().toString().padStart(2, '0');
        const monthStart = (startDate.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are zero-indexed
        const hourStart = startDate.getHours().toString().padStart(2, '0');
        const minuteStart = startDate.getMinutes().toString().padStart(2, '0');
      

        const dayEnd = endDate.getDate().toString().padStart(2, '0');
        const monthEnd = (endDate.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are zero-indexed
        const hourEnd = endDate.getHours().toString().padStart(2, '0');
        const minuteEnd = endDate.getMinutes().toString().padStart(2, '0');



        var remaining = (hours < 10 ? "0" : "") +  hours + ":" + (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds

        var widthProgress = "0%";

        var tradingProgressDiv;

        if (
            (currentUTCHour === 20 && currentUTCMinutes >= 50) || currentUTCHour === 21 || 
            (currentUTCHour === 22 && currentUTCMinutes === 0) ||
            (currentUTCDay === 5 && (currentUTCHour > 20 || (currentUTCHour === 20 && currentUTCMinutes >= 50))) || // Friday after 20:50 UTC
            currentUTCDay === 6 || // All of Saturday
            (currentUTCDay === 0 && currentUTCHour < 23) // Sunday before 23:00 UTC
          ) {
            remaining = "Market Closed";
            widthProgress = "0%";

            tradingProgressDiv = undefined
          } else {
            const totalDuration = endDate - startDate; // Total duration in milliseconds
            const elapsedTime = now - startDate; // Elapsed time in milliseconds
          
            let progress = (elapsedTime / totalDuration) * 100; // Progress as a percentage
            widthProgress = Math.max(0, Math.min(100, progress)) + "%"; // Clamp value between 0 and 100%
          

            tradingProgressDiv = 
                <div className='trading-day-container'>
                    <div className='trading-day-start'>{dayStart}/{monthStart} {hourStart}:{minuteStart}</div>
                    <div className='trading-day-progress'>
                        <div className='trading-day-progress-bar' style={{width: widthProgress}}></div>

                        <div className='trading-day-remaining'>{remaining}</div>
                    </div>
                    <div className='trading-day-end'>{dayEnd}/{monthEnd} {hourEnd}:{minuteEnd}</div>

                </div>
            
          }
                    return (

                        <div>
                            <div className='home-grid'>
                                <div className='home-grid-panel'>
                                    <div className='home-grid-panel-title '> Today's Realised</div>
                                    <div className={'home-grid-panel-value pnl-green' }>{this.numberWithCommas(todaysRealised.toFixed(2))}</div>
                                    <div className='home-grid-panel-sub'>FX Swap: <span className={swapProfit >= 0 ? 'pnl-green' : 'pnl-red'}>{swapProfit > 0 ? '+' : ''}{(swapProfit).toFixed(2)}</span></div>
                                    <div className={'home-grid-panel-sub' }>Total: <span className='pnl-green'>{this.numberWithCommas((todaysRealised+swapProfit).toFixed(2))}</span></div>
                                     <div className='home-grid-panel-sub'><span>{((((todaysRealised)/lastEquity)*100.0).toFixed(2))}%</span> of equity </div>
                                </div>


                                <div className='home-grid-panel'>
                                    <div className='home-grid-panel-title '> NOP</div>
                                    <div className={'home-grid-panel-value ' + nopColor}>{this.numberWithCommas(Number.parseFloat(this.props.nop.utilization).toFixed(2))}%</div>
                                </div>

                                
                            </div>

                            


                    

                            <div className='home-grid'>
                                <div className='home-grid-panel'>
                                    <div className='home-grid-panel-title '> Today's Stats</div>

                                    {tradingProgressDiv}

                                    <div className='home-grid-panel-row'>
                                        <div className='home-grid-panel-row-key'>Volume</div>
                                        <div className='home-grid-panel-row-value'>${this.numberWithCommas(this.props.todaysVolume, 0)}</div>
                                    </div>

                                    <div className='home-grid-panel-row'>
                                        <div className='home-grid-panel-row-key'>Number of Trades</div>
                                        <div className='home-grid-panel-row-value'>{this.numberWithCommas(this.props.todaysTradeNumber, 0)}</div>
                                    </div>

                                    <div className='home-grid-panel-row'>
                                        <div className='home-grid-panel-row-key'>Fees</div>
                                        <div className='home-grid-panel-row-value pnl-red'>${this.numberWithCommas(this.props.todaysFees)}</div>
                                    </div>


                                    <div className='home-grid-panel-row'>
                                        <div className='home-grid-panel-row-key'>Cost/million</div>
                                        <div className='home-grid-panel-row-value pnl-red'>${this.numberWithCommas(this.props.todaysVolume === 0 ? 0 : Number.parseFloat(this.props.todaysFees)/Number.parseFloat(this.props.todaysVolume/1000000.0), 2)}</div>
                                    </div>

                                    <div className='home-grid-panel-row'>
                                        <div className='home-grid-panel-row-key'>Profit/Yard</div>
                                        <div className='home-grid-panel-row-value pnl-green'>${this.numberWithCommas(this.props.todaysVolume === 0 ? 0 : todaysRealised/(this.props.todaysVolume/1000000000.0), 0)}</div>
                                    </div>

                                </div>


                                
                            </div>

                           

                            <div className='home-grid'>
                                <div className='home-grid-panel'>
                                    <div className='home-grid-panel-title '> Starting Equity</div>
                                    <div className={'home-grid-panel-value ' }>$1,200,000</div>
                                </div>


                                
                            </div>

                            <div className='home-grid-panel-gauges'>
                                    <div className='home-grid-panel-title '> Servers</div>

                                    <div className='chart_div home-grid-panel-gauges-list' >
                                        <Chart
                                        chartType="Gauge"
                                        width="100%"
                                        height="400px"
                                        data={this.getServerData()}
                                        options={optionsServerChart}
                                        />
                                        

                                        </div>
                            </div>

                            



                        </div>
                    


                        
                        
                        );
                }
            }              
export default Home;