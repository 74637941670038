import logo from './logo.svg';
import './App.css';
import './Dashboard'
import Password from './Components/Password';
import Dashboard from './Dashboard';

function App() {

  var requirePassword = true;

  if (requirePassword) {
    if (localStorage.getItem("loggedIn") !== "true") {
      return (
        <div className="App">
          <Password />
        </div>
      );
    }
  }

  return (
    <div className="App">
      <Dashboard />
    </div>
  );
}

export default App;
